$primary-red-color: #ff514e;
$primary-yellow-color: #fbb403;
$primary-white-color: #fff;
$primary-black-color: #000000d6;
$primary-grey-color: rgba(0, 0, 0, 0.4);
$primary-success-color: #43d787;
$primary-favourite-color: #f766ad;
$primary-warning-color: #ffbb00;
$primary-error-color: #f9461c;

$wishlist-color: #434655;
$error-color: #ff0000;
$disabled-color: #bfbfbf;
$scrollbar-color: #909090;
$scrollbar-background-color: #f5f5f5;
