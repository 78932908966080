@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (max-width: 960px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media (max-width: 960px) {
      @content;
    }
  }

  @if $breakpoint == mobileMedium {
    @media (max-width: 460px) {
      @content;
    }
  }

  @if $breakpoint == mobileSmall {
    @media (max-width: 340px) {
      @content;
    }
  }

  @if $breakpoint == tabletFix {
    @media (min-width: 740px) and ( max-width: 1024px) {
      @content;
    }
  }
  
  @if $breakpoint == desktop {
    @media (min-width: 960px) {
      @content;
    }
  }
}
