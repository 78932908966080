.home {
    display: flex;
    .homeContainer {
        flex: 6;

        .widgets, .chart {
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .charts {
            display: flex;
            padding: 5px 20px;
        }
    }
}