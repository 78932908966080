.Drag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.list-container {
  display: flex;
  font-size: 14px;
  background-color: #eee;
  flex-direction: column;
}
.item-container {
  background-color: #fff;
  border: 1px solid black;
  padding: 25px 50px;
  margin: 15px 30px;
}
/*.input  {
  padding: 0.3rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  color: red;
  background-color: #fff;
}*/